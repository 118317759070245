import React, { useEffect, useState, useMemo } from "react";
import { useUser } from "reactfire";
import agMapping from "../../assets/json/ag_to_eclipse_mapping.json";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import AutogateOnlineReportBySiteComponent from "./AutogateOnlineReportBySiteComponent";
import "@inovua/reactdatagrid-community/index.css";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const internalSiteGUID = agMapping.map((item) => item.guid);
const gridStyle = { minHeight: 700 };

const carsalesStockLocatorUrlPrefix =
  "https://stocklocator.carsalesnetwork.com.au/details/";

const defaultFilterValue = [
  {
    name: "Title",
    operator: "contains",
    type: "string",
    value: "",
  },

  { name: "Price", operator: "gte", type: "number", value: 0 },
  {
    name: "Site",
    operator: "contains",
    type: "string",
    value: "",
  },
  {
    name: "State",
    operator: "contains",
    type: "string",
    value: "",
  },
  //   filter date
  //   {
  //     name: "CreatedUtcEPOC",
  //     operator: "gte",
  //     type: "number",
  //   },
];

const defFilters = {
  State: "",
  Site: "",
  Title: "",
  Price: 0,
};

const AutogateListingsComponent = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [showExternal, setShowExternal] = useState(false);
  const [filterValue, setFilterValue] = useState(defaultFilterValue);
  const [filters, setFilters] = useState(defFilters);
  const [stateGroupedData, setStateGroupedData] = useState([]);

  // const firestore = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const user = useUser();
  const isAdminUser = useMemo(
    () =>
      ["j.jayaram@duttongroup.com.au", "dv@airvehicles.com.au"].includes(
        user.data.email
      ),
    [user]
  );

  const columns = useMemo(
    () => [
      {
        name: "Ad",
        header: "Ad",
        width: 100,
        frozen: true,
        render({ data }) {
          return (
            <a
              href={`${carsalesStockLocatorUrlPrefix}${data.Identifier}`}
              target="_blank"
              rel="noreferrer"
            >
              {`Open Ad`}
            </a>
          );
        },
      },
      {
        name: "State",
        header: "State",
        width: 100,
        resizable: true,
      },
      {
        name: "Site",
        header: "Site",
        width: 150,
        resizable: true,
      },
      {
        name: "Thumbnail",
        header: "Thumbnail",
        width: 150,

        render({ data }) {
          return <img src={data.Thumbnail} style={{ height: 90 }} />;
        },
      },
      {
        name: "Title",
        header: "Title",
        width: 400,
        resizable: true,
        //   render({ data }) {
        //     return data.Specification?.Title;
        //   },
      },
      {
        name: "Price",
        header: "Price",
        width: 100,
        resizable: true,
        render({ data }) {
          return (
            <NumberFormat
              value={data.Price}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          );
        },
      },

      {
        name: "CreatedUtcEPOC",
        header: "Uploaded To Autogate",
        width: 200,
        resizable: true,
        render({ data }) {
          // Days from today and CreatedUtc
          const daysFromToday = dayjs().diff(data.CreatedUtc, "day");
          const colour = daysFromToday > 61 ? "red" : "green";
          return (
            <span style={{ color: colour }}>{`${daysFromToday} days ago`}</span>
          );
          // return dayjs(data.CreatedUtc).format("YYYY-MM-DD HH:mm:ss");
        },
      },
    ],
    []
  );

  // Get inventory listing data
  useEffect(() => {
    setIsLoading(true);
    const unsub = firestore
      .collection("autogate-inventory-distribution")
      .where("SaleStatus", "==", "For Sale")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          // toast.info("No actions found");
          setData([]);
          return;
        }
        const td = [];

        snapshot.forEach((doc) => {
          const tmpData = doc.data();

          td.push({
            id: doc.id,
            ...tmpData,
          });
        });

        setData(td);
      });
    return () => unsub();
  }, []);

  useEffect(() => {
    const td = [];
    for (let i = 0; i < data.length; i++) {
      const tmpData = data[i];
      if (!tmpData) continue;
      if (
        internalSiteGUID.includes(tmpData.Seller?.Identifier) ||
        showExternal
      ) {
        let Site = "";
        if (internalSiteGUID.includes(tmpData.Seller?.Identifier)) {
          Site = getVehicleSite(tmpData.Seller?.Identifier);
        } else {
          Site = `${tmpData.Seller?.Name}`;
        }
        const Title = tmpData.Specification?.Title;
        let Price = 0;
        if (tmpData.PriceList) {
          Price = tmpData.PriceList[0]?.Amount;
        }
        const Thumbnail = tmpData.Media?.Photos[0]?.Url;

        let State = "";
        if (tmpData.Seller?.Addresses?.length > 0) {
          State = tmpData.Seller?.Addresses[0]?.State;
        }
        // convert UTC to local time CreatedUtc using dayjs
        const CreatedUtc = dayjs(tmpData.CreatedUtc).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        // Convert UTC to epoch time using dayjs
        const CreatedUtcEPOC = dayjs(tmpData.CreatedUtc).unix();

        td.push({
          ...tmpData,
          Site,
          Title,
          Price,
          Thumbnail,
          CreatedUtc,
          CreatedUtcEPOC,
          State,
        });
      }
      setIsLoading(false);
    }
    setRows(td);
  }, [JSON.stringify(data), showExternal]);

  const filteredRows = useMemo(() => {
    return rows.filter((r) => {
      return (
        // (filterValue.State ? r.State.includes(filterValue.State) : true)
        // (filterValue.priority !== 'All' ? r.priority === filterValue.priority : true) &&
        // (filterValue.issueType !== 'All' ? r.issueType === filterValue.issueType : true) &&
        r.State.toLowerCase().includes(filters.State.toLowerCase()) &&
        r.Title.toLowerCase().includes(filters.Title.toLowerCase()) &&
        r.Site.toLowerCase().includes(filters.Site.toLowerCase())
        // r.price >= filterValue.price

        // (filterValue.complete !== undefined ? r.complete >= filterValue.complete : true)
      );
    });
  }, [rows, filters]);

  const setStateFilterValue = (state) => {
    const tmpFilterValue = filterValue.map((item) => {
      if (item.name === "State") {
        return { ...item, value: state };
      }
      return item;
    });
    setFilterValue(tmpFilterValue);
    onFilterValueChange(tmpFilterValue);
  };

  const setBrandFilterValue = (brand) => {
    const tmpFilterValue = filterValue.map((item) => {
      if (item.name === "Site") {
        return { ...item, value: brand };
      }
      return item;
    });
    setFilterValue(tmpFilterValue);
    onFilterValueChange(tmpFilterValue);
  };

  const onFilterValueChange = (fv) => {
    const tmpFilters = fv.reduce((acc, item) => {
      acc[item.name] = item.value;
      return acc;
    }, {});
    setFilters(tmpFilters);
    setFilterValue(fv);
  };

  //   Renders
  const getVehicleSite = (siteId) => {
    const site = agMapping.find((item) => item.guid === siteId);
    return site?.eclipse_name || "NA";
  };

  useEffect(() => {
    // Group by State and then Site
    const groupedData = rows.reduce((acc, item) => {
      if (!acc[item.State]) {
        acc[item.State] = {};
      }
      if (!acc[item.State][item.Site]) {
        acc[item.State][item.Site] = [];
      }
      acc[item.State][item.Site].push(item);
      return acc;
    }, {});

    // Convert to array
    const groupedDataArray = Object.keys(groupedData).map((state) => {
      // Convert groupedData[state] to array
      const siteArray = Object.keys(groupedData[state]).map((site) => {
        return {
          Site: site,
          ...groupedData[state][site],
        };
      });

      // console.log(siteArray);

      return {
        State: state,
        siteArray,
      };
    });

    setStateGroupedData(groupedDataArray);
    // console.log(groupedDataArray);
  }, [JSON.stringify(rows)]);

  return (
    <>
      <h4>Autogate Listings</h4>
      <AutogateOnlineReportBySiteComponent onlineData={stateGroupedData} />
      <Card className="m-2">
        <Card.Header>Filters</Card.Header>
        <Card.Body>
          {isAdminUser ? (
            <Form.Check
              type="switch"
              id="show-external-switch"
              label="Include extenal listings"
              size={"lg"}
              style={{ marginBottom: 20 }}
              onChange={() => setShowExternal(!showExternal)}
            />
          ) : null}
          <Row className="mb-2">
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    filters.Site === "D1 " ? "primary" : "outline-primary"
                  }
                  onClick={() => setBrandFilterValue("D1 ")}
                >
                  Dutton One
                </Button>
                <Button
                  variant={
                    filters.Site === "AMW " ? "primary" : "outline-primary"
                  }
                  onClick={() => setBrandFilterValue("AMW ")}
                >
                  AMW
                </Button>
                <Button
                  variant={
                    filters.Site === "Dutton Garage"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => setBrandFilterValue("Dutton Garage")}
                >
                  Dutton Garage
                </Button>
                <Button
                  variant={
                    filters.Site === "Classic Throttle Shop"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => setBrandFilterValue("Classic Throttle Shop")}
                >
                  CTS
                </Button>
                <Button
                  variant={filters.Site === "" ? "warning" : "outline-warning"}
                  onClick={() => setBrandFilterValue("")}
                >
                  All
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonGroup>
                <Button
                  variant={
                    filters.State === "Victoria" ? "primary" : "outline-primary"
                  }
                  onClick={() => setStateFilterValue("Victoria")}
                >
                  VIC
                </Button>
                <Button
                  variant={
                    filters.State === "New South Wales"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => setStateFilterValue("New South Wales")}
                >
                  NSW
                </Button>
                <Button
                  variant={
                    filters.State === "Queensland"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => setStateFilterValue("Queensland")}
                >
                  QLD
                </Button>
                <Button
                  variant={
                    filters.State === "South Australia"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => setStateFilterValue("South Australia")}
                >
                  SA
                </Button>
                <Button
                  variant={filters.State === "" ? "warning" : "outline-warning"}
                  onClick={() => setStateFilterValue("")}
                >
                  All
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading ? <Spinner animation="border" variant="primary" /> : null}
      &nbsp; &nbsp;
      {`Found ${filteredRows.length} in filtered list out of ${rows.length}`}
      <ReactDataGrid
        key={`data-grid-ag-listings`}
        idProperty="id"
        // idProperty={`data-grid-main-${stage}`}

        style={gridStyle}
        // activateRowOnFocus={activateRowOnFocus}
        pagination={true}
        defaultFilterValue={defaultFilterValue}
        filterValue={filterValue}
        columns={columns}
        // rows={filteredRows}
        dataSource={filteredRows}
        onFilterValueChange={onFilterValueChange}
        // onReady={() => console.log("Grid ready")}
        // onDataSourceCacheChange={() => console.log("Cache changed")}
        enableFiltering={true}
        // checkboxColumn={checkboxColumn}
        // checkboxOnlyRowSelect={true}
        enableSelection
        rowHeight={100}
        virtualized={true}

        // multiSelect
        // onSelectionChange={onSelectionChange}
      />
    </>
  );
};

export default AutogateListingsComponent;
